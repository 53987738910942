.v-application a {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #000000;
    font-family: "Poppins", sans-serif;
}
.v-application a:hover {
    text-decoration: none;
}

.v-application .primary{
    color: var(--primaryText) !important;

}
/* button.v-app-bar__nav-icon.text-dark{
    color: var(--primaryText) !important;
} */

.top-bar-time {
    display: block;
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
}
.sidebar_nav {
    list-style: none !important;
}
.no-transition .stepper__content {
    transition: none;
}

.v-navigation-drawer__content {
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    background: linear-gradient(
        150deg,
        rgba(255, 255, 255, 0.4) 0%,
        rgba(255, 255, 255, 0) 60%
    ) !important;
    filter: drop-shadow(5px 4px 15px rgba(0, 0, 0, 0.1));
    border-radius: 8px;
    margin: 15px;
    box-shadow: 0 1rem 3rem rgb(0 0 0 / 18%) !important;
}

.st-card {
    background: rgba(229, 229, 229, 0.2);
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.1);
    border-radius: 8px !important;
    margin: 5px;
    padding: 20px 10px;
    background-color: #fff;
    height: 125px;
    transition: 0.3s linear all;
}

.st-card-home {
    background: rgba(229, 229, 229, 0.2);
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.1);
    border-radius: 8px !important;
    margin: 5px;
    padding: 10px;
    background-color: #fff;
    min-height: 120px;
    transition: 0.3s linear all;
}
.v-slide-group__wrapper {
    padding-top: 5px;
    padding-bottom: 5px;
}
.btn-tool .v-toolbar__content {
    float: right;
}
/* .btn-tool .v-btn__content {
    color: #000000 !important;
} */
.cancel-btn {
    background: #ffffff !important;
    color:black !important;
    box-shadow: 4.74952px 0.949903px 8.54913px rgba(204, 204, 204, 0.59);
    border-radius: 7.59923px !important;
    width: 130px;
}


.submit-btn {
    background: var(--primary)!important;
    color: var(--primaryText) !important;
    box-shadow: 4.74952px 0.949903px 8.54913px rgba(204, 204, 204, 0.59);
    border-radius: 7.59923px !important;
    /* width: 130px; */
}

.primary-text-color{
    color: var(--primaryText) !important;
}

.secondary-text-color{
    color: var(--secondaryText) !important;
}

.v-slide-group__wrapper a {
    font-weight: 500;
    text-transform: capitalize;
}
.icon svg {
    width: 40px;
    height: 40px;
}

.icon-home svg {
    width: 45px;
    height: 45px;
}
/* .st-card:hover{
    background: linear-gradient(180deg, #FED030 0%, #F3BF08 100%);
    box-shadow: 5px 1px 6px rgba(204, 204, 204, 0.25);
    transition: .3s linear all;
  } */

.st-card:hover {
    /*background: linear-gradient(180deg, #FFD9A7 0%, #FAD4A4 100%);*/
    box-shadow: 5px 1px 6px rgba(204, 204, 204, 0.25);
    transition: 0.3s linear all;
}

.st-card-home:hover {
    /* background: linear-gradient(180deg, #FFD9A7 0%, #FAD4A4 100%); */
    box-shadow: 5px 1px 6px rgba(204, 204, 204, 0.25);
    transition: 0.3s linear all;
}

.st-card .numbers {
    display: block;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 26.485px;
    line-height: 32px;
}

.st-card .leftcard {
    position: absolute;
    left: 50px;
    top: 35px;
    display: block;
    font-size: 18px;
}

.st-card-home .leftcard-home {
    display: block;
    font-size: 18px;
}
.st-card-home-title {
    /* display: block; */
    font-family: "Poppins";
    font-style: normal;
    font-variant: small-caps;
    font-weight: bold;
    font-size: 20.485px;
    text-align: center;
    line-height: 32px;
    margin-bottom: 20px;
}


.st-card-home-description {
    display: block;
    margin-top: 10px;
    font-family: "Poppins";
    font-style: normal;
    font-size: 14px;
    font-variant: normal;
    line-height: 20px;
}

.st-card-home-submit-btn {
    display: block;
    margin-top: 10px;
    font-family: "Poppins";
    font-style: normal;
    font-size: 16px;
    font-variant: normal;
    text-align: center;
}

.st-card .name {
    text-transform: capitalize;
    display: block;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    margin-bottom: 15px;
}

.st-card .icon {
    position: absolute;
    display: block;
    font-size: 18px;
    width: 60.68px;
    height: 61.44px;
    /* background:  #FFD9A7; */
    border-radius: 30px;
    padding: 10px;
    right: 39px;
}

.st-card-home .icon-home {
    /*position: absolute;*/
    display: block;
    font-size: 18px;
    width: 65.68px;
    height: 66.44px;
    /* background:  #FFD9A7; */
    border-radius: 50px;
    padding: 5px;
    margin-top: -50px;
    /*right: 158px;*/
    top: 5px;
}
.st-card:hover .icon {
    background: #fff;
}

.st-card-home:hover .icon-home {
    background: #fff;
}
.st-bg-icon {
    background: rgba(255, 255, 255, 0.78) !important;
    border-radius: 50% !important;
    color: #0a0a0a !important;
}
.st-tabs {
    /* background-color:  #FFD9A7; */
}
.mx-70 {
    margin-left: 70px;
    margin-right: 70px;
}
.v-list-item__icon {
    margin-top: 8px !important;
    width: 24px !important;
}
.st-app-bar-title {
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 1.5rem !important;
    line-height: 32px !important;
}

.st-app-bar {
    border-bottom-right-radius: 20px !important;
    border-bottom-left-radius: 20px !important;
    margin-bottom: 40px !important;
}
.v-list--dense .v-list-item .v-list-item__content {
    padding: 0px !important;
}

.card-container {
    background: rgba(229, 229, 229, 0.15) !important;
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
    padding: 40px !important;
    margin-top: 30px;
}

.router-link-active {
    box-sizing: border-box;
    width: 100%;
    border-left: 4px solid;
    background: rgba(255, 255, 255, 0.4);
}

.logo-bottom {
    -webkit-align-self: flex-end;
    align-self: flex-end;
    /* background:  #FFD9A7; */
    margin-top: auto;
    padding: 15px;
    width: 100%;
    position: -webkit-sticky;
    position: fixed;
    bottom: 0;
    left: 0;
}

.sub-item-list .v-list-group__header {
    display: none !important;
}
.sub-item {
    padding-left: 30px !important;
}

.v-toolbar__content {
    flex-wrap: wrap !important;
}

.topnav_icon {
    margin-top: -15px !important;
}

.v-stepper__content {
    padding: 0px !important;
}
.row.pt-5.no-gutters {
    padding-top: 0px !important;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
    transition: opacity 0.5s;
}
.modal-fade-enter,
.modal-fade-leave-to {
    opacity: 0;
}

    .candidate-table-striped tbody tr:nth-child(odd) {
      background-color: #f2f2f2;
    }

    .v-snack__content {
        text-align: center !important;
        text-align: center
    }

    .v-snack__wrapper {
        min-width: 100px;
        max-width: 1000px;
    }


@media screen and (max-width: 600px) {
    .v-alert__wrapper {
        display: block !important;
    }
}

@media screen and (max-width: 870px) {
    .institution_name {
        font-size: 20px;
        /* margin: 0px auto; */
    }
}

@media screen and (max-width: 720px) {
    .institution_name {
        font-size: 13px;
        /* margin: 0px auto; */
    }
}

@media screen and (max-width: 400px) {
    .institution_name {
        font-size: 11px;
        /* margin: 0px auto; */
    }
}
